import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetEmergencyFundInvestorGoalCalculatorQuery
  from '@/modules/flagship/emergency-fund-investor-goal-calculator/application/queries/get-emergency-fund-investor-goal-calculator-query';
import CalculateCustomGoalMaximumMonthsQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-maximum-months-query';
import EditEmergencyFundGoalPlanBudgetService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-budget-service';
import EditEmergencyFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-plan-service';
import EditEmergencyFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-amount-service';
import EditEmergencyFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-define-by-service';

// Domain
import { EmergencyFundInvestorGoalCalculatorDto }
  from '@/modules/flagship/emergency-fund-investor-goal-calculator/domain/dtos/emergency-fund-investor-goal-calculator-dto';
import {
  CustomGoalMaximumMonthsCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-maximum-months-dto';
import { DefineByEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/define-by-entity';
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/current-plan-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditEmergencyFundDefineByViewModel {
  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_CALCULATOR_QUERY)
  private readonly get_emergency_fund_calculator_query!:
    GetEmergencyFundInvestorGoalCalculatorQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MAXIMUM_MONTHS_QUERY)
  private readonly calculate_custom_goal_maximum_months_query!:
    CalculateCustomGoalMaximumMonthsQuery;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_BUDGET_SERVICE)
  private readonly budget_service!: EditEmergencyFundGoalPlanBudgetService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditEmergencyFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditEmergencyFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditEmergencyFundGoalPlanDefineByService;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-emergency-fund-goal-plan.define_by';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  timer?: NodeJS.Timer;

  your_budge = {
    monthly_income: '0',
    monthly_expenses: '0',
    monthly_savings_capacity: 0,
    new_emergency_fund: '0',
  }

  current_plan_information: CurrentPlanEntity = {
    target_amount: '',
    current_amount: '',
    monthly_required_amount: '',
    final_investment_date: '',
    associated_product_id: '',
    emergency_fund_id: '',
  }

  defined_by = 'by_time';

  months_to_reach_goal = 6;

  min_months_to_reach_goal = 1;

  max_months_to_reach_goal = 48;

  months_to_reach_goal_by_contribution = 0;

  contribution = '';

  min_contribution = 1;

  max_contribution = 0;

  initial_amount = 0;

  slider_contribution = 1;

  monthly_required_amount = 0;

  accumulated_amount_by_time = 0;

  accumulated_amount_by_contribution = 0;

  maximum_months = 48;

  is_define_by_contribution = false;

  is_valid_form = false;

  is_loading_monthly_payment = false;

  input_rules = {
    contribution: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get target_amount_formatted() {
    return this.getAmountFormatted(this.parseCurrencyToNumber(this.your_budge.new_emergency_fund));
  }

  get you_must_save() {
    return (this.is_define_by_contribution) ? this.months_to_reach_goal_by_contribution
      .toFixed(1) : this.getAmountFormatted(this.monthly_required_amount);
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading_monthly_payment;
  }

  getCustomClassOpacity = (is_option_by_time: boolean) => {
    this.defined_by = (!this.is_define_by_contribution) ? 'by_time' : 'by_contribution';
    if (!this.is_define_by_contribution && !is_option_by_time) return 'opacity';
    if (this.is_define_by_contribution && is_option_by_time) return 'opacity';
    return '';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBudgetInformation = () => (this.budget_service.getBudgetInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  setDefineByInformation = (define_by: DefineByEntity) => {
    this.define_by_service.setDefineByInformation(define_by);
  };

  calculateMaximumMonths = async () => {
    try {
      if (this.initial_amount > 0) {
        this.is_loading_monthly_payment = true;
        const payload: CustomGoalMaximumMonthsCalculationDto = {
          monthly_required_amount: 0,
          initial_amount: this.initial_amount,
          desired_amount: parseFloat(this.your_budge.new_emergency_fund),
          interest_rate: 0.055,
        };
        const calculation = await this
          .calculate_custom_goal_maximum_months_query.internalExecute(payload);
        this.maximum_months = Math.floor(parseFloat(calculation.maximum_months));
        this.months_to_reach_goal = this.maximum_months;
        this.max_months_to_reach_goal = this.maximum_months;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  calculateMaximumMonthlyAmount = async () => {
    try {
      this.is_loading_monthly_payment = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.current_plan_information.associated_product_id,
        defined_by: 'time',
        desired_amount: parseFloat(this.your_budge.new_emergency_fund),
        initial_amount: this.initial_amount,
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
      };
      const calculated_data = await this
        .get_emergency_fund_calculator_query.execute(payload);
      this.max_contribution = Math.ceil(
        calculated_data.emergency_fund_calculation.monthly_required_amount,
      );
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  setInitialValues = async () => {
    const budget_information = this.getBudgetInformation();
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    const define_by = this.getDefineByInformation();
    this.initial_amount = this.parseCurrencyToNumber(current_plan.current_amount);
    let new_emergency_amount = budget_information.new_emergency_fund;
    if (current_amount.there_is_more_savings === 'yes' && current_amount.strategy_selected === 'other') {
      new_emergency_amount -= current_amount.additional_amount;
    }
    if (current_amount.there_is_more_savings === 'yes' && current_amount.strategy_selected === 'pocket') {
      this.initial_amount += current_amount.additional_amount;
    }
    this.your_budge = {
      monthly_income: String(budget_information.monthly_income),
      monthly_expenses: String(budget_information.monthly_expenses),
      monthly_savings_capacity: budget_information.monthly_savings_capacity,
      new_emergency_fund: String(new_emergency_amount),
    };
    this.current_plan_information = { ...current_plan };
    await this.calculateMaximumMonths();
    await this.calculateMaximumMonthlyAmount();
    if (define_by.by_time.months_to_reach_goal > 0) {
      this.is_define_by_contribution = define_by.is_defined_by_contribution;
      this.months_to_reach_goal = define_by.by_time.months_to_reach_goal;
      this.monthly_required_amount = define_by.by_time.you_must_save;
      this.months_to_reach_goal_by_contribution = define_by.by_contribution.months_to_reach_goal;
      this.contribution = String(define_by.by_contribution.contribution);
      this.slider_contribution = define_by.by_contribution.contribution;
      this.accumulated_amount_by_time = define_by.by_time.accumulated_amount;
      this.accumulated_amount_by_contribution = define_by.by_contribution.accumulated_amount;
      await this.monthlyPaymentCalculation();
    } else {
      this.delay(true);
    }
  }

  validateContributionIsLessThanMax = () => {
    const parsed_contribution = this.parseCurrencyToNumber(this.contribution);
    if (parsed_contribution > this.max_contribution) {
      this.contribution = this.max_contribution.toFixed(0);
    }
    this.slider_contribution = this.parseCurrencyToNumber(this.contribution);
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  monthlyPaymentCalculation = async () => {
    try {
      this.is_loading_monthly_payment = true;
      const payload: EmergencyFundInvestorGoalCalculatorDto = {
        associated_product_id: this.current_plan_information.associated_product_id,
        defined_by: (this.is_define_by_contribution) ? 'contribution' : 'time',
        desired_amount: this.parseCurrencyToNumber(this.your_budge.new_emergency_fund),
        initial_amount: this.initial_amount,
        monthly_required_amount: (this.is_define_by_contribution) ? this
          .parseCurrencyToNumber(this.contribution) : 0,
        fixed_time_adjusted: (this.is_define_by_contribution) ? 0 : this.months_to_reach_goal,
      };
      const { emergency_fund_calculation } = await this
        .get_emergency_fund_calculator_query.execute(payload);
      const {
        accumulated_amount, monthly_required_amount, fixed_time_adjusted,
      } = emergency_fund_calculation;
      if (this.is_define_by_contribution) {
        this.months_to_reach_goal_by_contribution = fixed_time_adjusted;
        this.accumulated_amount_by_contribution = accumulated_amount;
      } else {
        this.monthly_required_amount = Math.ceil(monthly_required_amount);
        this.accumulated_amount_by_time = accumulated_amount;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_monthly_payment'));
    } finally {
      this.is_loading_monthly_payment = false;
    }
  }

  delay = (is_defined_by_tyme: boolean) => {
    this.is_loading_monthly_payment = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      if (!is_defined_by_tyme) this.validateContributionIsLessThanMax();
      await this.monthlyPaymentCalculation();
    }, 2000);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  setNewContribution = () => {
    this.contribution = String(this.slider_contribution);
    this.delay(false);
  }

  nextStep = () => {
    const define_information: DefineByEntity = {
      is_defined_by_contribution: this.is_define_by_contribution,
      by_time: {
        months_to_reach_goal: this.months_to_reach_goal,
        you_must_save: this.monthly_required_amount,
        accumulated_amount: this.accumulated_amount_by_time,
      },
      by_contribution: {
        contribution: this.parseCurrencyToNumber(this.contribution),
        months_to_reach_goal: this.months_to_reach_goal_by_contribution,
        accumulated_amount: this.accumulated_amount_by_contribution,
      },
    };
    this.setDefineByInformation(define_information);
    this.view.$emit('nextStep');
  }

  initialize = async () => {
    await this.setInitialValues();
  }
}
